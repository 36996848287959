@import "src/scss/module";

.OfficeCard {
  background-color: transparent;
  border: none;
  --card-font-size: #{$font-size-base};

  &[data-size="small"] {
    --card-font-size: #{$small-font-size};
  }

  a {
    color: inherit;

    &:hover {
      color: #{$green} !important;
    }
  }

  :global {
    .card-header {
      color: #{$green};
      font-weight: bold;
      background-color: transparent;
      border: none;
      padding: 0;

      h6 {
        font-size: var(--card-font-size);
      }
    }

    .card-body {
      padding: 0 0 1rem 0;

      :global {
        .btn-link {
          padding-left: 0;
          padding-right: 0;
        }
      }

      address {
        p {
          font-size: var(--card-font-size);
        }
      }
    }

    .card-footer {
      padding: 0;
      background-color: transparent;
      border: none;

      dl {
        font-size: var(--card-font-size);
        font-weight: #{$font-weight-bold};
      }
    }
  }
}
